.vh-fill {
    min-height: calc(100vh - 180px);
}

.vh-70 {
    min-height: 70vh;
}

.header-box {
    background-color: hsla(0, 0%, 4%, 0.4);
    color: #ffffff;
}

.powur-btn-primary {
    background-color: #20C2F1;
    border: #20C2F1;
}

.trackNowBtn{
    position: relative;
    top: -1px;
    left: -3px;
    border-radius: 0px;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
}

.powur-btn-primary:hover {
    background-color: #2CB8DF;
    border: #2CB8DF;
    
}

.powur-btn-secondary {
    background-color: #ffffff;
    border: 1px solid #20C2F1;
    color: #20C2F1;
}

.powur-btn-secondary:hover {
    background-color: #2CB8DF;
    border: 1px solid #2CB8DF;
}


.accordion-button:not(.collapsed) {
    background-color: #f5f5f5;
    color: #444;
    font-weight: 600;
}

.powur-link {
    color: #20C2F1 !important;
    text-decoration: none !important;
}

.bg-powur-blue {
    background-color: #20C2F1;
}

.radio-images {
    width: 70%;
}

.ant-radio-button-wrapper {
    height: 100%;
    padding-bottom: 10px;    
}

.ant-radio-group {
    height: 100%;
}

.powur-disclaimer {
    font-size: small;
}

.ant-input[disabled]{
    color: black !important;
  }
  